// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-cows-en-js": () => import("./../../../src/pages/cows.en.js" /* webpackChunkName: "component---src-pages-cows-en-js" */),
  "component---src-pages-cows-es-js": () => import("./../../../src/pages/cows.es.js" /* webpackChunkName: "component---src-pages-cows-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-sale-en-js": () => import("./../../../src/pages/sale.en.js" /* webpackChunkName: "component---src-pages-sale-en-js" */),
  "component---src-pages-sale-es-js": () => import("./../../../src/pages/sale.es.js" /* webpackChunkName: "component---src-pages-sale-es-js" */),
  "component---src-templates-cow-detail-js": () => import("./../../../src/templates/cow-detail.js" /* webpackChunkName: "component---src-templates-cow-detail-js" */)
}

